import { Select, Modal, Radio, Space, Divider, Spin, Tooltip, Typography, DatePicker, } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/buttonCustom";
import Api from "../../../services/Api";
import { AiOutlineVerticalAlignBottom, AiOutlineReload, } from "react-icons/ai";
import { DownLoadAttendnaceLogs, getLogsData, getLogsDetails, userSelectCalendar, applyLeaveModalApi, } from "../action/action";
import { useAttendnaceStore } from "../store/store";
import { getFilterValues } from "../../ProjectManagement/action/action";
import { useProjecteStore } from "../../ProjectManagement/store/store";
import moment from "moment";
import UpdateAttendanceModal from "./updateAttendance";
import ManualPunchModal from "./multiplePunches";
import DownloadModal from "./customDownload";
import GeoFence from "./geoFence";
import DownloadIcon from "../../../assets/Download Icon Blue.svg";
import FilterIcon from "../../../assets/filterIcon.svg";
import classes from "../../AttendancePortal/Logs/Calender.module.css";
import ApplyLeave from "./applyLeave";
import CalendarIcon from "../../../assets/calender_white_icon.svg";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import MobileBackIcon from "../../../assets/mobileBackIcon.svg";
import NextIcon from "../../../assets/nextIconBlack.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Views from "../../../services/ViewRole";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';

const { Option } = Select;
const CalendarIconPage = () => {
  const navigate = useNavigate();

  const year = moment().format("YYYY");
  const {
    setLogData, setTableMetaData, setRender,
    selectedUser, setLoader, loader, attendanceLogsData, setAttendanceLogsData, openGeofence,
    setOpenGeofence, userId, setApplyLeaveModal, updateModal, setUpdateModal,
    manualPunchModal, setManualPunchModal, userCalendarDrop, setUserCalendarDrop,
    selectAssociate, tabs, value, setValue, Data, setData, selectData, setSelectData,
    setSelectAssociate, uiSelectId, setUiSelectId, filterModal, setFilterModal, filterModalLogs, setFilterModalLogs,
    setApplyLeaveData, monthsVal, setMonthsVal, open, setOpen, search,
    date, setDate, currentPage, setCurrentPage, pageSize, filteredDeptValue, setFilteredDeptValue,
    filteredManagerValue, setFilteredManagerValue, sortingEmployee, isApplyFiltersModal,
    applyFiltersModalHandler, btnLoader, setBtnLoader, startDate, endDate, associateIdInLog, setAssociateIdInLog
  } = useAttendnaceStore((state) => state);

  const { associateId } = useParams();

  useEffect(() => {
    let id = localStorage.getItem("setAssociateIdInLog")
    setAssociateIdInLog(id)

  }, [])


  // console.log("ssssAssociateId",associateId,associateIdInLog,uiSelectId,selectedUser?.userId)
  // const location = useLocation();
  // const { pathname } = location;
  // const associateId = pathname.split('/').pop();

  const { setFilterValues, filteredData, selectedAssociateName, setSelectedAssociateName } = useProjecteStore((state) => state);
  const { width } = useWindowDimensions();
  const dates = `${moment().format("MM").slice(0, 3)},${year}`
  const setColor = {
    absent: "#F64A3F",
    leave: "#FD8600",
    Advanced: "#581845",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    "Forgot Punch": "#FB29FF ",
    present: "#0AAD43",
    "Week Off": "#999999",
    admin: "",
    optional: "#34FFDA",
    // "half day": "#CC6760",
    "Outdoor Punch": "#005000",
    // "paternity" : "#9A8745",
    // "wedding" : "#C2F339",
    // maternity:"#9A8745"
  };
  const colors = {
    present: "#0AAD43",
    leave: "#FD8600",
    Advanced: "#581845",
    "Week Off": "#999999",
    admin: "",
    optional: "#34FFDA",
    "Forgot Punch": "#FB29FF ",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    // "half day": "#CC6760",
    "Outdoor Punch": "#005000",
    // "paternity" : "#9A8745",
    // "wedding" : "#C2F339",
    // "maternity": "#9A8745"
  };

  const colorsType = {
    "paternity": "#9A8745",
    "wedding": "#C2F339",
    "leave": "#FD8600",
    Advanced: "#581845",
    holiday: "linear-gradient(100deg, #FDDC7A 20%,red )",
    "Forgot Punch": "#FB29FF",
    "present": "#0AAD43",
    "Week Off": "#999999",
    admin: "",
    "optional": "#34FFDA",
    // "half day": "#CC6760",
    "Outdoor Punch": "#005000",
  };

  const monthNameToNumber = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',

    '01': '01',
    '02': '02',
    '03': '03',
    '04': '04',
    '05': '05',
    '06': '06',
    '07': '07',
    '08': '08',
    '09': '09',
    '10': '10',
    '11': '11',
    '12': '12',
  };

  const monthFullName = {
    '01': "January",
    '02': "February",
    '03': "March",
    '04': "April",
    '05': "May",
    '06': "June",
    '07': "July",
    '08': "August",
    '09': "September",
    '10': "October",
    '11': "November",
    '12': "December",

    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  }
  // const url = new URL(window.location.href);

  useEffect(() => {
    Views.updateView();
  }, [])

  useEffect(() => {
    userSelectCalendar((res) => {
      if (tabs === 'Active Users') {
        setUserCalendarDrop(res?.activeUsers);
      }
      else if (tabs === 'Inactive Users') {
        setUserCalendarDrop(res?.inactiveUsers);

      }
    });
  }, []);


  const onDownload = (values) => {
    DownLoadAttendnaceLogs(
      applyFiltersModalHandler(false)
      , `/attendance-logs/custom-download`, {
      departmentId: values.departmentId,
      teamId: values.teamId,
      startDate: values.startDate,
      endDate: values.endDate,
      exEmployee: tabs === "Inactive Users" ? true : false
    }, 1);
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // useEffect(() => {
  //     getFilterValues(setFilterValues, "departmentAttendance");
  //   }, [tabs,pageSize,currentPage,filteredDeptValue,filteredManagerValue,sortingEmployee]);

  const Calendar = ({ calendar, startingDay }) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    const startingIndex = daysOfWeek.indexOf(moment(startingDay).format("ddd"));
    const emptyCells = Array(startingIndex).fill(null);

    const currentDate = moment();
    const isCurrentDate = (date) => {
      return moment(date).isSame(currentDate, "day");
    };
        
    const isFutureDate = (date) => {
      return moment(date).isAfter(currentDate, "day");
    };


    const formatTime = (timeStr) => {
      if (timeStr) {
        const [hours, minutes] = timeStr.split(":");
        return `${hours}:${minutes}`;
      }
      return "";
    };



    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: width > 700 && "100%",
          // marginTop:"0.3rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            // height:width>700 && "10%",
            alignItems: "center",
            boxShadow: width > 700 && "0px 2px 2px rgba(0, 0, 0, 0.20)",
            // marginBottom:width>700 && "0.5rem",
            padding: "0.9vh 1rem",
            backgroundColor: "#FFFFFF",
            borderRadius: width > 700 && "5px 5px 0 0",
            borderBottom: "1px solid #DFDFDF",
          }}
        >
          <div
            // className={prev > 1 ? classes.disabled : ""}
            onClick={() => {
              const currentMonth = moment(monthsVal === null ? dates : monthsVal, 'MM,YYYY');
              const previousMonth = currentMonth.clone().subtract(1, 'month');
              const previousMonthFormatted = previousMonth.format('MM,YYYY');
              const paramsvalue = previousMonthFormatted.split(',')

              setMonthsVal(previousMonthFormatted);
              getLogsDetails(
                (res) => {
                  setAttendanceLogsData(res);
                },
                {
                  userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                  month: paramsvalue[0],
                  year: paramsvalue[1],
                },
                setLoader
              );
            }}
            style={{ cursor: "pointer", fontSize: "0.5vw", color: "grey" }}
          >
            <img src={MobileBackIcon} alt="" style={{ height: "2.3vh", width: "2.3vw" }} />
          </div>
          <div style={{ color: "#000000", fontWeight: "600", fontSize: width < 700 ? "17px" : "1.2vw" }}>

            {monthsVal === null ? `${monthFullName[dates.split(",")[0]]}, ${dates.split(",")[1]}`
              : `${monthFullName[monthsVal.split(",")[0]]}, ${monthsVal.split(",")[1]}`}
          </div>
          <div
            // className={next > 0 ? classes.disabled : ""}
            onClick={() => {
              const currentMonth = moment(monthsVal === null ? dates : monthsVal, 'MM,YYYY');
              const nextMonth = currentMonth.clone().add(1, 'month');
              const nextMonthFormatted = nextMonth.format('MM,YYYY');
              const paramsvalue = nextMonthFormatted.split(',')

              setMonthsVal(nextMonthFormatted);
              getLogsDetails(
                (res) => {
                  setAttendanceLogsData(res);
                },
                {
                  //   userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                  userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                  month: paramsvalue[0],
                  year: paramsvalue[1],
                },
                setLoader
              );
            }}
            style={{ cursor: "pointer", fontSize: "1.5vw", color: "grey" }}
          >
            <img src={NextIcon} alt="" style={{ height: "2.3vh", width: "2.3vw" }} />
          </div>
        </div>

        <div
          // className="calendar"
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: width > 700 ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "0px 1px 1px rgba(0, 0, 0, 0.25)",
            backgroundColor: "#FFFFFF",
            borderRadius: "0 0 5px 5px", paddingBottom: "0.5vh",
            // height:width>700&&"91%"
          }}
        >
          <div className="days" style={{ width: "100%", padding: "1vh 0" }}>
            {daysOfWeek?.map((day, index) => (
              <div
                key={day}
                className={`day-of-week ${day}`}
                style={{
                  width: "100%", flexDirection: "row", justifyContent: "center", fontSize: "1.1vw", paddingBottom: "0.5vh",
                  display: "flex", alignItems: "center", color: "#16243280", borderBottom: "1px solid #DFDFDF"
                  // color: index === 0 || index === 6 ? "#ABABAB" : "#0086FF",
                }}
              >
                {day}
              </div>
            ))}
          </div>
          <div
            className="days"
            style={{ width: "100%", overflow: "auto" }}
          >
            {emptyCells?.map((_, index) => (
              <div
                key={`empty-${index}`}
              // style={{ backgroundColor: index === 0 || index === 5 ? "#D1D1D1" : "black" }}
              ></div>
            ))}
            {calendar?.map((day) => (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", borderBottom: "1px solid #DFDFDF" }}>

                {day?.checkInManual && day?.checkOutManual ?
                <Tooltip
                  title={
                  <div>
                    <p>
                    {
                      day?.effort === "leave" && day?.leaveType !== "Lop" ? `${day?.leaveType} Leave`
                        : day?.effort === "leave" && day?.leaveType === "Lop" ? day?.leaveType
                          : day?.effort === "manual" ? day?.type
                            : day?.effort === "present" ? day?.effort
                              : day?.effort === "absent" ? day?.effort
                                : day?.effort === "half day" ? day?.effort
                                  : day?.effort === "holiday" ? day?.effort
                                    : day?.effort === "optional" ? "Optional Holiday"
                                      : day?.effort === "notJoined" ? "Not Joined"
                                        : day?.effort === "Week Off" ? "Week Off"
                                        :day?.effort==="Comp_Init"?"CompOff Init"
                                        :
                                        day?.effort==="Comp_Req"? "CompOff Request"
                                          : ""
                    }
                    </p>
                    <p>Applied</p>
                       <p>
                      {
                      
                          day?.checkInManual !== null && day.checkOutManual !== null ? `${formatTime(day.checkInManual)}-${formatTime(day.checkOutManual)}`
                              : "00:00-00:00"
                      }
                    </p> 
                    <p>Actual</p>
                    <p>
                      {
                      day?.checkIn !== undefined && day.checkOut === undefined ? `${formatTime(day.checkIn)}-00:00`
                        : day?.checkIn === null && day.checkOut !== null ? `00:00-${formatTime(day.checkOut)}`
                          : day?.checkIn !== null && day.checkOut === null ? `${formatTime(day.checkIn)}-00:00`
                            : day?.checkIn !== null && day.checkOut !== null ? `${formatTime(day.checkIn)}-${formatTime(day.checkOut)}`
                              : "00:00-00:00"
                      }
                    </p> 
                   
                  
                  </div>
                    }
                  key={day?.day}
                >
                  {/* <div style={{display:"flex",flexDirection:"row",justifyContent: "center",border:"2px solid"}}> */}
                  <div
                    onClick={() => {
                      setUpdateModal(true);
                      setData(day);
                      setSelectData(day);
                    }}
                    id={day?.date}
                    style={{
                      // marginLeft: "1.5rem",
                      cursor: "pointer",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "2.3vw",
                      height: "4.5vh",
                    }}
                  >
                    <div

                      style={{
                        width: "2.3vw",
                        height: "4.5vh",
                        display: "flex",
                        boxShadow: (day?.status === "Pending"&& day?.leaveType!=="Lop" && !isCurrentDate(new Date(day?.date))) && "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        //boxShadow: (day?.status==="Pending" && day?.leaveType==="Lop"&& (day?.effort==="leave"||day?.secondEffort==="leave"))&&"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        border: day?.admin === true ? "1px solid #666666" : day?.hr === true ? "1px solid #666666"
                          // :day?.status==="Pending"? "1px solid #9747FF"
                          : "",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        fontSize: width < 700 ? 16 : "1.15vw",
                        fontWeight: 600,
                        backgroundColor: day?.effort === "leave" && day?.leaveType === "Comp_off" ? "brown" : "red",

                        background:
                        colorsType&& day?.leaveType==="Lop" && day?.effort ==="leave" && !day?.half && !day?.secondEffort ?"red":
                        colorsType&& day?.leaveType==="Lop" && day?.effort === "leave" && day?.half === true && isFutureDate(new Date(day?.date)) ? "linear-gradient(to right ,  red 50% ,transparent 50% )":
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort === "leave" && day?.half === true && isFutureDate(new Date(day?.date)) ? "linear-gradient(to right   ,transparent 50%,red 50%  )":
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort ==="leave" && day?.effort==="manual" ?
                        day?.type==="Forgot Punch" ? "linear-gradient( to right, #FB29FF  50%, red 50%)":"linear-gradient(to right , #005000 50%, red 50%)":
 
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort === "leave" && day?.effort==="present" ? "linear-gradient( to right, #0AAD43  50%, red 50%)":
                         
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort === "leave" && day?.effort==="absent" ? "linear-gradient( to right, #F64A3F  50%, red 50%)":
 
                        colorsType&& day?.leaveType==="Lop" && day?.effort ==="leave" && day?.secondEffort==="manual" ?
                        day?.type==="Forgot Punch" ? "linear-gradient( to right, red 50%, #FB29FF  50%)":"linear-gradient(to right , red 50%, #005000 50%)":
 
                        colorsType&& day?.leaveType==="Lop" && day?.effort === "leave" && day?.secondEffort==="present" ? "linear-gradient( to right, red 50%,  #0AAD43  50%)":
                         
                        day?.leaveType==="Lop" && day?.effort === "leave" && day?.secondEffort==="absent" && day?.half===true ? "linear-gradient( to right, red 50%,  #F64A3F  50%)":
 
 
                        
                     
                        colorsType &&
                          day?.effort === "absent" ? setColor[day?.effort]
                            : day?.effort === "holiday" && !isCurrentDate(new Date(day?.date)) ? "linear-gradient(100deg, #FDDC7A 40%,red)"
                              : isCurrentDate(new Date(day?.date)) ? "#0086FF"
                                : day?.effort === "leave" && day?.leaveType === "Comp_off" && day?.half !== true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && !isCurrentDate(new Date(day?.date)) ? "linear-gradient(85deg, #0DAC45 30%, #0086FF )"
                                : day?.status === "Pending" && (day?.effort ==="present" || day?.secondEffort ==="present") && (day?.half) ? day?.checkIn<"12:00:00" ? "linear-gradient( to right,  #0AAD43 50%,#fff 50%)": "linear-gradient( to right, #fff 50% , #0AAD43 50%)" 
                                : day?.status === "Pending" && (day?.effort === "absent" || day?.secondEffort === "absent") &&(day?.half)? day?.checkIn>"12:00:00" ? "linear-gradient( to right, #fff 50%,  #F64A3F 50%)": "linear-gradient( to right,  #F64A3F 50%, #fff 50%)"
                                //: day?.status === "Pending" && (day?.effort === "manual" || day?.secondEffort === "manual") && (day?.type=== "Forgot Punch") &&(day?.half)? day?.checkIn>"12:00:00" ? "linear-gradient( to right, #fff 50%,   #FB29FF 50%)": "linear-gradient( to right,  #FB29FF 50%, #fff 50%)"
                                //: day?.status === "Pending" && (day?.effort === "manual" || day?.secondEffort === "manual") && (day?.type=== "Outdoor Punch") &&(day?.half)? day?.checkIn>"12:00:00" ? "linear-gradient( to right, #fff 50%, #005000 50%)": "linear-gradient( to right, #005000 50%, #fff 50%)"
                                  
                                // :colorsType && day?.effort === "manual" &&  day?.status!=="Pending" && day?.half !== true? setColor[day?.effort]
                                  // day?.half === true && day?.status!=="Pending"
                                  : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FB29FF  50%, #FD8600 50%)"
                                    : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                                      : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #FB29FF  50%, #581845 50%)"
                                        : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #005000 50%, #581845 50%)"
                                          : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "absent" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #F64A3F 50%)"
                                            : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "absent" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #F64A3F 50%)"
                                              : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "present" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #0AAD43 50%)"
                                                : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "present" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #0AAD43 50%)"
                                                  : day?.secondEffort === "absent" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #F64A3F 50%)"
                                                    : day?.secondEffort === "absent" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #F64A3F 50%)"
                                                      : day?.secondEffort === "present" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #0AAD43 50%)"
                                                        : day?.secondEffort === "present" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #0AAD43  50%)"
                                                          : day?.secondEffort === "leave" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #FD8600 50%)"
                                                            : day?.secondEffort === "leave" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                                                              : day?.secondEffort === "leave" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #581845 50%)"
                                                                : day?.secondEffort === "leave" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #005000 50%, #581845 50%)"
                                                                  : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #F64A3F 50%)"
                                                                    : day?.secondEffort === "present" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #0AAD43 50%)"
                                                                      : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #F64A3F 50%)"
                                                                        : day?.secondEffort === "present" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #0AAD43 50%)"
                                                                          // :day?.secondEffort === "manual" && day?.type==="Forgot Punch" && day?.half === true && day?.status!=="Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #FD8600 50%)"
                                                                          // :day?.secondEffort === "manual" && day?.type==="Outdoor Punch" && day?.half === true && day?.status!=="Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                                                                          : day?.secondEffort === "manual" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                                                                            : day?.secondEffort === "leave" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                                                                              : day?.secondEffort === "manual" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                                                                                : day?.secondEffort === "leave" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                                                                                  : day?.secondEffort === "present" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                                                                                    : day?.secondEffort === "leave" && day?.effort === "present" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                                                                                      : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"
                                                                                        : day?.secondEffort === "leave" && day?.effort === "absent" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"
                                                                                          : day?.effort === "half day" && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #0AAD43 50%, #F64A3F 50%)"
                                                                                            : day?.effort === "leave" && !isCurrentDate(new Date(day?.date)) && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "#581845"
                                                                                              : day?.effort === "leave" && !isCurrentDate(new Date(day?.date)) && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "#FD8600"
                                                                                                : day?.status === "Pending" && !isCurrentDate(new Date(day?.date)) ? ""
                                                                                                  : day?.status === "secondStatus" && !isCurrentDate(new Date(day?.date)) ? ""
                                                                                                    : day?.effort === "leave" && day?.leaveType === "Optional" && !isCurrentDate(new Date(day?.date)) ? "#34FFDA"
                                                                                                      : day?.effort === "manual" && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? setColor[day?.type]
                                                                                                        : day?.effort !== "Week Off" && day?.status !== "Pending" && day?.secondStatus !== "Pending" && setColor[day?.effort],
                        color:
                        day?.leaveType=="Lop"&& day?.effort=="leave" && !day?.half?"#FFFFFF":
                        day?.leaveType=="Lop" && (day?.effort=="leave"||day?.secondEffort === "leave") && day?.half===true && isFutureDate(new Date(day?.date))?"#000000":
                        day?.leaveType==="Lop" && day?.secondEffort === "leave" &&(day?.effort==="manual"|| day?.effort==="present"||day?.effort==="absent") ? "#FFFFFF":
                        day?.leaveType==="Lop" && day?.effort ==="leave" && (day?.secondEffort==="manual"||day?.secondEffort==="present"||day?.secondEffort==="absent") ?
                        "#FFFFFF": 

                          day?.effort=="Comp_Init" && day?.status=="Approved"?"#8FB804":
                          day?.effort=="Comp_Init" && day?.status=="Pending"?"red":
                          day?.effort=="Comp_Req" && day?.status=="Approved"?"#8FB804":

                       
                          day?.effort === "leave" && day?.leaveType === "Comp_off" && day?.half !== true && day?.status !== "Pending" ? "#FFFFFF" :
                            (day?.status === "Pending" && !isCurrentDate(new Date(day?.date)) && !(day?.admin || day?.hr))  ? "red" :
                              (day?.status === "Pending" && (day?.hr || day?.admin)) ? "red"
                                // : (day?.secondStatus==="Pending" && !isCurrentDate(new Date(day?.date))&&!(day?.admin||day?.hr)) ? "red":
                                // (day?.secondStatus==="Pending"&&(day?.admin||day?.hr))?""
                                : isCurrentDate(new Date(day?.date)) ? "#FFFFFF"
                                  : day?.effort === "holiday" ? "#FFFFFF"
                                    : setColor[day?.effort] === "#F64A3F" ? "#FFFFFF"
                                      : day?.effort === "half day" ? "#FFFFFF"
                                        : day?.secondEffort === "absent" && day?.effort === "manual" && day?.half === true ? "#FFFFFF"
                                          : colors[day?.effort] === "#999999" ? "#999999"
                                            : day?.secondEffort === "manual" && day?.half === true ? "#FFFFFF"
                                              : day?.effort === "manual" ? "#FFFFFF"
                                                : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true ? "#FFFFFF"
                                                  : setColor[day?.effort] ? "#FFFFFF"
                                                    : ""
                      }}
                    >
                      {day.day}
                    </div>
                  </div>
                  {/* </div> */}
                  </Tooltip>
                  
                  :
                  <Tooltip
                  title={
                  <div>
                    <p>
                    {
                      day?.effort === "leave" && day?.leaveType !== "Lop" ? `${day?.leaveType} Leave`
                        : day?.effort === "leave" && day?.leaveType === "Lop" ? day?.leaveType
                          : day?.effort === "manual" ? day?.type
                            : day?.effort === "present" ? day?.effort
                              : day?.effort === "absent" ? day?.effort
                                : day?.effort === "half day" ? day?.effort
                                  : day?.effort === "holiday" ? day?.effort
                                    : day?.effort === "optional" ? "Optional Holiday"
                                      : day?.effort === "notJoined" ? "Not Joined"
                                        : day?.effort === "Week Off" ? "Week Off"
                                        :day?.effort==="Comp_Init"?"CompOff Init"
                                        :
                                        day?.effort==="Comp_Req"? "CompOff Request"
                                          : ""
                    }
                    </p>
                    <p>
                      {
                      day?.checkIn !== undefined && day.checkOut === undefined ? `${formatTime(day.checkIn)}-00:00`
                        : day?.checkIn === null && day.checkOut !== null ? `00:00-${formatTime(day.checkOut)}`
                          : day?.checkIn !== null && day.checkOut === null ? `${formatTime(day.checkIn)}-00:00`
                            : day?.checkIn !== null && day.checkOut !== null ? `${formatTime(day.checkIn)}-${formatTime(day.checkOut)}`
                              : "00:00-00:00"
                      }
                    </p> 
                  </div>
                    }
                  key={day?.day}
                >
                  {/* <div style={{display:"flex",flexDirection:"row",justifyContent: "center",border:"2px solid"}}> */}
                  <div
                    onClick={() => {
                      setUpdateModal(true);
                      setData(day);
                      setSelectData(day);
                    }}
                    id={day?.date}
                    style={{
                      // marginLeft: "1.5rem",
                      cursor: "pointer",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "2.3vw",
                      height: "4.5vh",
                    }}
                  >
                    <div

                      style={{
                        width: "2.3vw",
                        height: "4.5vh",
                        display: "flex",
                        boxShadow: (day?.status === "Pending"&& day?.leaveType!=="Lop" && (!isCurrentDate(new Date(day?.date)))||(day?.effort==="leave"||day?.secondEffort==="leave") )&& "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        //boxShadow: (day?.status==="Pending" && day?.leaveType==="Lop"&& (day?.effort==="leave"||day?.secondEffort==="leave") )&&"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        border: day?.admin === true ? "1px solid #666666" : day?.hr === true ? "1px solid #666666"
                          // :day?.status==="Pending"? "1px solid #9747FF"
                          : "",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        fontSize: width < 700 ? 16 : "1.15vw",
                        fontWeight: 600,
                        backgroundColor: day?.effort === "leave" && day?.leaveType === "Comp_off" ? "brown" :  "red",
                      
                        textShadow: (day?.status === "Pending" && !isCurrentDate(new Date(day?.date)) && (day?.admin || day?.hr))  ? '5px 5px 5px #000':null,
                        background:
      
                        colorsType&& day?.leaveType==="Lop" && day?.effort ==="leave" && !day?.half && !day?.secondEffort ?"red":
                        colorsType&& day?.leaveType==="Lop" && day?.effort === "leave" && day?.half === true && isFutureDate(new Date(day?.date)) ? "linear-gradient(to right ,  red 50% ,transparent 50% )":
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort === "leave" && day?.half === true && isFutureDate(new Date(day?.date)) ? "linear-gradient(to right   ,transparent 50%,red 50%  )":
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort ==="leave" && day?.effort==="manual" ?
                        day?.type==="Forgot Punch" ? "linear-gradient( to right, #FB29FF  50%, red 50%)":"linear-gradient(to right , #005000 50%, red 50%)":
 
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort === "leave" && day?.effort==="present" ? "linear-gradient( to right, #0AAD43  50%, red 50%)":
                         
                        colorsType&& day?.leaveType==="Lop" && day?.secondEffort === "leave" && day?.effort==="absent" ? "linear-gradient( to right, #F64A3F  50%, red 50%)":
 
                        colorsType&& day?.leaveType==="Lop" && day?.effort ==="leave" && day?.secondEffort==="manual" ?
                        day?.type==="Forgot Punch" ? "linear-gradient( to right, red 50%, #FB29FF  50%)":"linear-gradient( to right , red 50%, #005000 50%)":
 
                        colorsType&& day?.leaveType==="Lop" && day?.effort === "leave" && day?.secondEffort==="present" ? "linear-gradient( to right, red 50% , #0AAD43  50%)":
                         
                        colorsType&& day?.leaveType==="Lop" && day?.effort === "leave" && day?.secondEffort=="absent"&& day?.half===true ? "linear-gradient( to right, red 50%,  #F64A3F  50%)":
 
 
                          day?.effort === "absent" ? setColor[day?.effort]
                            : day?.effort === "holiday" && !isCurrentDate(new Date(day?.date)) ? "linear-gradient(100deg, #FDDC7A 40%,red)"
                              : isCurrentDate(new Date(day?.date)) ? "#0086FF"
                                : day?.effort === "leave" && day?.leaveType === "Comp_off" && day?.half !== true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && !isCurrentDate(new Date(day?.date)) ? "linear-gradient(85deg, #0DAC45 30%, #0086FF )"
                                : day?.status === "Pending" && (day?.effort ==="present" || day?.secondEffort ==="present")  && (day?.half) ? day?.checkIn<"12:00:00" ? "linear-gradient( to right,  #0AAD43 50%,#fff 50%)": "linear-gradient( to right, #000 50% , #0AAD43 50%)"
                                : day?.status === "Pending" && (day?.effort === "absent" || day?.secondEffort === "absent")  && (day?.half)? day?.checkIn>"12:00:00" ? "linear-gradient( to right, #fff 50%,  #F64A3F 50%)": "linear-gradient( to right,  #F64A3F 50%, #fff 50%)"
                                //: day?.status === "Pending" && (day?.effort === "manual" || day?.secondEffort === "manual") && (day?.type=== "Forgot Punch") && (day?.half)? day?.checkIn>"12:00:00" ? "linear-gradient( to right, #fff 50%,   #FB29FF 50%)": "linear-gradient( to right,  #FB29FF 50%, #fff 50%)"
                                //: day?.status === "Pending" && (day?.effort === "manual" || day?.secondEffort === "manual") && (day?.type=== "Outdoor Punch")  && (day?.half)? day?.checkIn>"12:00:00" ? "linear-gradient( to right, #fff 50%, #005000 50%)": "linear-gradient( to right, #005000 50%, #fff 50%)"
                                  // :colorsType && day?.effort === "manual" &&  day?.status!=="Pending" && day?.half !== true? setColor[day?.effort]
                                  // day?.half === true && day?.status!=="Pending"
                                  : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FB29FF  50%, #FD8600 50%)"
                                    : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                                      : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #FB29FF  50%, #581845 50%)"
                                        : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #005000 50%, #581845 50%)"
                                          : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "absent" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #F64A3F 50%)"
                                            : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "absent" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #F64A3F 50%)"
                                              : day?.secondEffort === "manual" && day?.type === "Forgot Punch" && day?.effort === "present" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #0AAD43 50%)"
                                                : day?.secondEffort === "manual" && day?.type === "Outdoor Punch" && day?.effort === "present" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #0AAD43 50%)"
                                                  : day?.secondEffort === "absent" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #F64A3F 50%)"
                                                    : day?.secondEffort === "absent" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #F64A3F 50%)"
                                                      : day?.secondEffort === "present" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #0AAD43 50%)"
                                                        : day?.secondEffort === "present" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #0AAD43  50%)"
                                                          : day?.secondEffort === "leave" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #FD8600 50%)"
                                                            : day?.secondEffort === "leave" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                                                              : day?.secondEffort === "leave" && day?.type === "Forgot Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #581845 50%)"
                                                                : day?.secondEffort === "leave" && day?.type === "Outdoor Punch" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #005000 50%, #581845 50%)"
                                                                  : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #F64A3F 50%)"
                                                                    : day?.secondEffort === "present" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #0AAD43 50%)"
                                                                      : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #F64A3F 50%)"
                                                                        : day?.secondEffort === "present" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #0AAD43 50%)"
                                                                          // :day?.secondEffort === "manual" && day?.type==="Forgot Punch" && day?.half === true && day?.status!=="Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF  50%, #FD8600 50%)"
                                                                          // :day?.secondEffort === "manual" && day?.type==="Outdoor Punch" && day?.half === true && day?.status!=="Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #005000 50%, #FD8600 50%)"
                                                                          : day?.secondEffort === "manual" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                                                                            : day?.secondEffort === "leave" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                                                                              : day?.secondEffort === "manual" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                                                                                : day?.secondEffort === "leave" && day?.effort === "manual" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" && day?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                                                                                  : day?.secondEffort === "present" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                                                                                    : day?.secondEffort === "leave" && day?.effort === "present" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                                                                                      : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"
                                                                                        : day?.secondEffort === "leave" && day?.effort === "absent" && day?.half === true && day?.status !== "Pending" && day?.secondStatus === "Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"
                                                                                          : day?.effort === "half day" && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? "linear-gradient( to right, #0AAD43 50%, #F64A3F 50%)"
                                                                                            : day?.effort === "leave" && !isCurrentDate(new Date(day?.date)) && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType === "Advanced" ? "#581845"
                                                                                              : day?.effort === "leave" && !isCurrentDate(new Date(day?.date)) && day?.status !== "Pending" && day?.secondStatus !== "Pending" && day?.leaveType !== "Advanced" ? "#FD8600"
                                                                                                : day?.status === "Pending" && !isCurrentDate(new Date(day?.date)) ? ""
                                                                                                  : day?.status === "secondStatus" && !isCurrentDate(new Date(day?.date)) ? ""
                                                                                                    : day?.effort === "leave" && day?.leaveType === "Optional" && !isCurrentDate(new Date(day?.date)) ? "#34FFDA"
                                                                                                      : day?.effort === "manual" && day?.status !== "Pending" && day?.secondStatus !== "Pending" ? setColor[day?.type]
                                                                                                        : day?.effort !== "Week Off" && day?.status !== "Pending" && day?.secondStatus !== "Pending" && setColor[day?.effort],
                        color:
                        day?.leaveType=="Lop"&& day?.effort=="leave" && !day?.half?"#FFFFFF":
                        day?.leaveType=="Lop" && (day?.effort=="leave"||day?.secondEffort === "leave") && day?.half===true && isFutureDate(new Date(day?.date))?"#000000":
                        day?.leaveType==="Lop" && day?.secondEffort === "leave" &&(day?.effort==="manual"|| day?.effort==="present"||day?.effort==="absent") ? "#FFFFFF":
                        day?.leaveType==="Lop" && day?.effort ==="leave" && (day?.secondEffort==="manual"||day?.secondEffort==="present"||day?.secondEffort==="absent") ?
                        "#FFFFFF":                    
                          day?.effort=="Comp_Init" && day?.status=="Approved"?"#8FB804":
                          day?.effort=="Comp_Init" && day?.status=="Pending"?"red":
                          day?.effort=="Comp_Req" && day?.status=="Approved"?"#8FB804":

                       
                          day?.effort === "leave" && day?.leaveType === "Comp_off" && day?.half !== true && day?.status !== "Pending" ? "#FFFFFF" :
                            (day?.status === "Pending" && !isCurrentDate(new Date(day?.date)) && !(day?.admin || day?.hr))  ? "red" :
                              (day?.status === "Pending" && (day?.admin || day?.hr)) ? "red"
                                // : (day?.secondStatus==="Pending" && !isCurrentDate(new Date(day?.date))&&!(day?.admin||day?.hr)) ? "red":
                                // (day?.secondStatus==="Pending"&&(day?.admin||day?.hr))?""
                                : isCurrentDate(new Date(day?.date)) ? "#FFFFFF"
                                  : day?.effort === "holiday" ? "#FFFFFF"
                                    : setColor[day?.effort] === "#F64A3F" ? "#FFFFFF"
                                      : day?.effort === "half day" ? "#FFFFFF"
                                        : day?.secondEffort === "absent" && day?.effort === "manual" && day?.half === true ? "#FFFFFF"
                                          : colors[day?.effort] === "#999999" ? "#999999"
                                            : day?.secondEffort === "manual" && day?.half === true ? "#FFFFFF"
                                              : day?.effort === "manual" ? "#FFFFFF"
                                                : day?.secondEffort === "absent" && day?.effort === "leave" && day?.half === true ? "#FFFFFF"
                                                  : setColor[day?.effort] ? "#FFFFFF"
                                                    : ""
                      }}
                    >
                      {day.day}
                    </div>
                  </div>
                  {/* </div> */}
                  </Tooltip>

                }

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "0.7vh" }}>

                  <Typography.Text style={{
                    fontSize: "0.9vw", fontWeight: "bold", width: "10.5vw",
                    textAlign: ((day?.checkIn && day.checkOut||day?.checkInManual && day?.checkOutManual )&& (day?.effort === "holiday" || day?.effort === "leave" || day?.effort === "optional") ? `${day?.duration} Hrs`
                      : day?.effort === "leave" && day?.leaveType !== "Lop" ? `${day?.leaveType} Leave`
                        : day?.effort === "leave" && day?.leaveType === "Lop" ? day?.leaveType
                          : day?.effort === "present" ? `${day?.duration} Hrs`
                            : day?.effort === "absent" ? "00:00 Hrs"
                              : day?.effort === "half day" ? day?.effort
                                : day?.effort === "holiday" || day?.effort === "optional" ? day?.accountOff
                                :day?.accountOff?day?.accountOff
                                  : day?.effort === "notJoined" ? "Not Joined"
                                    : day?.effort === "Week Off" ? "   "
                                      : "    ").length <= 9 ? "center" : "left",

                  }}
                    ellipsis={{ tooltip: true }}>
                    <span style={{ fontSize: "0.8vw", fontWeight: 300 }}>
                      {(day?.checkIn && day.checkOut||day?.checkInManual&& day?.checkOutManual) ? `${day?.duration} Hrs`
                        : day?.effort === "present" ? `${day?.duration} Hrs`
                          : day?.effort === "absent" ? "00:00 Hrs"
                            : "00:00 Hrs"}
                    </span>
                    <span style={{ fontSize: "0.8vw", fontWeight: 300, marginLeft: "0.3rem" }}>
                      {
                        // day?.effort==="present"&&day?.checkIn && day.checkOut ? `${formatTime(day.checkIn)}-${formatTime(day.checkOut)}`
                        // day?.checkIn && day.checkOut && (day?.effort==="holiday" || day?.effort==="leave" || day?.effort==="optional") ? `${day?.duration} Hrs`
                        day?.effort === "leave" && day?.leaveType !== "Lop" ? `${day?.leaveType} Leave`
                          : day?.effort === "leave" && day?.leaveType === "Lop" ? day?.leaveType
                            // :day?.effort==="manual" && day?.status !== "Pending" ? day?.type
                            // :day?.effort==="manual" && day?.status === "Pending" ? day?.type  
                            // :day?.effort==="manual" && day?.status === "Pending" ? `${day?.duration} Hrs`
                            // :day?.effort==="present"? `${day?.duratsion} Hrs`
                            // :day?.effort==="absent"? "00:00 Hrs"
                            : day?.effort === "half day" ? day?.effort
                              : day?.effort === "holiday" || day?.effort === "optional" ? day?.accountOff
                              :day?.accountOff?day?.accountOff
                                : day?.effort === "notJoined" ? "Not Joined"
                                  : day?.effort === "Week Off" ? "   "
                                    : "    "
                      }
                    </span>
                    <span  style={{ fontSize: "0.8vw", fontWeight: 300, marginLeft: "0.3rem" }}>
                    {
                      day?.compOffInitializeDate?
                      `${" "}${ day?.compOffInitializeDate}`
                      :
                     " "
                    }
                    </span>
                  </Typography.Text>



                </div>
                <div style={{ fontSize: "0.8vw", fontWeight: 300, marginLeft: "0.3rem", paddingBottom: "1rem" }}>
                  {day?.punchInfo !== null && day?.punchInfo?.FinalType ? day?.punchInfo?.FinalType : ""}
                </div>
              </div>
            ))}
          </div>
          <style>
            {`
              .calendar {
                // font-family: Arial, sans-serif;
                font-family: Poppins;
              }
              .days-of-week {
                display: flex;
                justify-content: space-between;
                margin-bottom: 80px;
              }
              .S{
                color:#ABABAB
                border: 1px solid
              }
              .dual-color{
                linear-gradient(90deg, #7ED321 50%, #F64A3F 50%)
              }
              .day-of-week {
                font-weight: bold;
                color:#0086FF
              }
              .days {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: 8px;
                font-size:14px
              }
              .day {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #ccc;
                padding: 2px;
              }
              .empty {
                  
              }
              .day-of-month {
                font-weight: bold;
                margin-bottom: 5px;
              }
              .current-date {
                color: #FFFFFF;
                background: linear-gradient(100deg, #FDDC7A 28%,red );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .compOff{
                background: red;
                color: black;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .absent {
                border-radius: 10px;
              }
              `}
          </style>
        </div>
      </div>
    );
  };

  const handleDownload = () => {
    getLogsDetails(
      (res) => {
        setAttendanceLogsData(res);
      },
      {
        // userId: uiSelectId ? uiSelectId : selectedUser?.userId,
        userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
        // month : monthNameToNumber[monthsVal.split(",")[0]],
        // year: monthsVal.split(",")[1],
        month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
        year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
        download: true,
        ExEmployee: tabs === "Inactive Users" ? true : false,
      },
      setLoader
    );
  };



  const LogsDataCard = () => {
    const dayDescription = [
      "Present",
      "LOP",
      "Holiday",
      "Leave",
      "Outdoor Punch",
      "Forgot Punch",
      "Advanced Leave",
      "Admin / Hr",
      "Optional",
      // "Casual Leave",
      // "Privilege Leave",
      "Week Off",
      "Half day",
      // "Wedding",
      // "Maternity/Paternity",
      "Comp Off",
    ];
    const containerRef = useRef(null);

    const dayDesColor = (item) => {
      let background = "#39CA6C";
      if (item === "Holiday") {
        background = "linear-gradient(#FDDC7A, #FE9F58)";
      } else if (item === "LOP") {
        background = "#F64A3F";
      } else if (item === "Leave") {
        background = "#FD8600";
      } else if (item === "Outdoor Punch") {
        background = "#005000";
      } else if (item === "Half day") {
        background = "linear-gradient(#39CA6C, #F64A3F)";
      } else if (item === "Forgot Punch") {
        background = "#FB29FF";
      } else if (item === "Advanced Leave") {
        background = "#581845";
      } else if (item === "Week Off") {
        background = "#999999";
      } else if (item === "Admin / Hr") {
        background = "#FFFFFF";
      } else if (item === "Optional") {
        background = "#34FFDA";
      }
      //  else if (item === "Comp Off") {
      //   background = "linear-gradient(#0DAC45, #0086FF)";
      // }

      let Styles = {
        width: "2rem",
        height: "1rem",
        borderRadius: "20%",
        backgroundColor: background,
      };

      return Styles;
    };

    const handleScrollLeft = () => {
      const container = containerRef.current;
      container.scrollLeft -= 280;
    };

    const handleScrollRight = () => {
      const container = containerRef.current;
      container.scrollLeft += 280;
    };

    // console.log("ssssdeleSelectMonthsValue",monthsVal)

    return (
      <>
        <div>
          {width < 700 &&
            <div
              style={{
                display: "flex",
                margin: width > 700 ? "0.8rem 1.2rem" : "0.8rem 1.1rem",
                // background: "#F9F9F9",
                flexDirection: "column",
              }}
            >
              {width < 700 &&
                // <section className={classes.hours} style={{fontSize:"1vw"}}>
                // <div>
                //   <div style={{color:"#16243280"}}>
                //     <span>Total Working Hours</span>
                //     <span>Forgot Punches</span>
                //     <span>Outdoor Punches</span>
                //   </div>
                //   <div style={{fontWeight:"600"}}>
                //     <span>{attendanceLogsData?.totalWorkingHours}&nbsp;hrs</span>
                //     <span>{attendanceLogsData?.forgotPunches}</span>
                //     <span>{attendanceLogsData?.OutdoorPunches}</span>
                //   </div>
                // </div>

                // <div>
                //   <div style={{color:"#16243280"}}>
                //     <span>Extra Working Days</span>
                //     <span>Extra Working Hours</span>
                //   </div>
                //   <div style={{fontWeight:"600"}}>
                //     <span>{attendanceLogsData?.extraWorkingDays}</span>
                //     <span>{attendanceLogsData?.extraWorkingHours}&nbsp;hrs</span>
                //   </div>
                // </div>

                // <div>
                //   <div style={{color:"#16243280"}}>
                //     <span>Total Planned Hours</span>
                //     <span>Total Actual Hours</span>
                //   </div>
                //   <div style={{fontWeight:"600"}}>
                //     <span>{attendanceLogsData?.totalPlannedHours}&nbsp;hrs</span>
                //     <span>{attendanceLogsData?.totalActualHours}&nbsp;hrs</span>
                //   </div>
                // </div>
                // </section>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", }}>
                  <div onClick={handleScrollLeft} style={{ display: "flex", alignItems: "center", marginRight: "0.4rem" }}>
                    <LeftOutlined style={{ height: "" }} /></div>
                  <section className={classes.hours2} style={{}} ref={containerRef}>
                    <div>
                      <div>
                        <span>Total Working Hours</span>
                        <span>Forgot Punches</span>
                        <span>Outdoor Punches</span>
                      </div>
                      <div style={{ fontWeight: "600" }}>
                        <span>{attendanceLogsData?.totalWorkingHours}&nbsp;hrs</span>
                        <span>{attendanceLogsData?.forgotPunches}</span>
                        <span>{attendanceLogsData?.OutdoorPunches}</span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <span>Extra Working Days</span>
                        <span>Extra Working Hours</span>
                      </div>
                      <div style={{ fontWeight: "600" }}>
                        <span>{attendanceLogsData?.extraWorkingDays}</span>
                        <span>{attendanceLogsData?.extraWorkingHours}&nbsp;hrs</span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <span>Total Planned Hours</span>
                        <span>Total Actual Hours</span>
                      </div>
                      <div style={{ fontWeight: "600" }}>
                        <span>{attendanceLogsData?.totalPlannedHours}&nbsp;hrs</span>
                        <span>{attendanceLogsData?.totalActualHours}&nbsp;hrs</span>
                      </div>
                    </div>
                  </section>
                  <div onClick={handleScrollRight} style={{ display: "flex", alignItems: "center", marginLeft: "0.4rem" }}>
                    <RightOutlined /></div>
                </div>
              }
            </div>}

          {width > 700 ?
            <div style={{
              display: "flex", flexDirection: "column", gap: "0.7rem", width: "100%", justifyContent: "space-between",
              padding: "0.7rem 1rem 0.5rem 1rem", height: width > 700 && "100%",
            }}>
              {/* <div
                  style={{
                    width: "27%",
                    borderRadius: "5px",
                    display: "flex",flexDirection:"column",gap:"0.5rem"
                  }}
                > */}
              <div className={classes.hours} style={{ fontSize: "1vw", width: "100%", overflow: "auto" }}>
                {/* <div>
                  <div style={{color:"#16243280",fontSize:"1vw"}}>
                    <span>Total Working Hours</span>
                    <span>Forgot Punches</span>
                    <span>Outdoor Punches</span>
                    <span>Extra Working Days</span>
                    <span>Extra Working Hours</span>
                    <span>Total Planned Hours</span>
                    <span>Total Actual Hours</span>
                  </div>
                  <div style={{fontWeight:"600",fontSize:"1vw"}}>
                    <span>{attendanceLogsData?.totalWorkingHours}&nbsp;hrs</span>
                    <span>{attendanceLogsData?.forgotPunches}</span>
                    <span>{attendanceLogsData?.OutdoorPunches}</span>
                    <span>{attendanceLogsData?.extraWorkingDays}</span>
                    <span>{attendanceLogsData?.extraWorkingHours}&nbsp;hrs</span>
                    <span>{attendanceLogsData?.totalPlannedHours}&nbsp;hrs</span>
                    <span>{attendanceLogsData?.totalActualHours}&nbsp;hrs</span>
                  </div>
                </div> */}
                <div>
                  <div style={{ color: "#878787" }}>
                    <span>Total Working Hours</span>
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    <span>{attendanceLogsData?.totalWorkingHours}</span>
                  </div>
                </div>
                <div>
                  <div style={{ color: "#878787" }}>
                    <span>Forgot Punches</span>
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    <span>{attendanceLogsData?.forgotPunches}</span>
                  </div>
                </div>
                <div>
                  <div style={{ color: "#878787" }}>
                    <span>Outdoor Punches</span>
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    <span>{attendanceLogsData?.OutdoorPunches}</span>
                  </div>
                </div>
                <div>
                  <div style={{ color: "#878787" }}>
                    <span>Extra Working Days</span>
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    <span>{attendanceLogsData?.extraWorkingDays}</span>
                  </div>
                </div>
                <div>
                  <div style={{ color: "#878787" }}>
                    <span>Extra Working Hours</span>
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    <span>{attendanceLogsData?.extraWorkingHours}</span>
                  </div>
                </div>
                <div>
                  <div style={{ color: "#878787" }}>
                    <span>Total Planned Hours</span>
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    <span>{attendanceLogsData?.totalPlannedHours}</span>
                  </div>
                </div>
                <div>
                  <div style={{ color: "#878787" }}>
                    <span>Total Actual Hours</span>
                  </div>
                  <div style={{ fontWeight: "600" }}>
                    <span>{attendanceLogsData?.totalActualHours}</span>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", paddingRight: "0 0.5rem", flexDirection: "column", backgroundColor: "#FFFFFF", boxShadow: width > 700 && "2px 2px 2px rgba(0, 0, 0, 0.20)", }}>
                <h5 style={{ padding: "1vh 0.5rem 0 0.5rem", fontSize: "1.15vw", fontWeight: 600 }}>Attendance Color Codes</h5>
                <div
                  className={classes.overflowColors}
                  style={{ height: "5vh", display: "flex", flexDirection: "row", gap: "0.2rem", width: "99%", overflow: "scroll", margin: "0.1rem 0.5rem", }}
                >
                  {dayDescription.map((item, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center", gap: "0.3vw", }}
                    >
                      <div
                        style={{
                          background: item === "Holiday" ? "linear-gradient(80deg, #FDDC7A 35%, #FE9F58)"
                            : item === "Half day" ? "linear-gradient(50deg, #39CA6C 50%, #F64A3F 50%)"
                              : item === "Comp Off" && "linear-gradient(80deg, #0DAC45, #0086FF)",
                          ...dayDesColor(item),
                          border:
                            item === "Admin / Hr"
                              ? "1px solid #666666"
                              : item === "Optional"
                                ? "1px dotted #666666"
                                : "none",
                        }}
                      ></div>
                      <div style={{ width: item.length > 8 ? "7vw" : "5vw", padding: "", fontSize: "1vw", }}>
                        <Typography.Text style={{ fontSize: "1vw", color: "#878787" }} ellipsis={{ tooltip: true }}>
                          <span>{item}</span>
                        </Typography.Text>
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ width: "100%" }}>
                  <p style={{ color: "#878787", padding: "0 0.5rem 1vh 0.5rem", fontWeight: "550px", fontSize: "0.85vw" }}>Note: Our intuitive system showcases all full day pending requests
                    in <span style={{ color: "red" }}>red</span> text color and half-day pending requests in <span style={{ color: "#9747FF" }}>purple</span> background color.
                    Once approved, they are transform into a specified filled colors.
                  </p>
                </div>
              </div>
              {/* </div> */}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // height:width>700&&"100%",
                  // marginBottom:"10vh"
                }}
              >
                <Calendar
                  startingDay={moment(
                    attendanceLogsData?.calendar?.[0]?.date
                  ).format()}
                  calendar={
                    attendanceLogsData?.calendar
                      ? attendanceLogsData?.calendar
                      : []
                  }
                />
                {/* <Calender/> */}
              </div>
            </div>
            :
            <div style={{ width: "100%", padding: "0.3rem 1rem", }}>
              <div style={{ marginBottom: "0.9rem" }}>
                <Calendar
                  startingDay={moment(
                    attendanceLogsData?.calendar?.[0]?.date
                  ).format()}
                  calendar={
                    attendanceLogsData?.calendar
                      ? attendanceLogsData?.calendar
                      : []
                  }
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "25rem",
                  marginTop: "0.5rem",
                  backgroundColor: "#FFFFFF",
                  boxShadow: width > 700 ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "0px 1px 1px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    height: "25rem",
                    width: "100%",
                  }}
                >
                  {dayDescription.map((item, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center", }}
                    >
                      <div
                        style={{
                          background: item === "Holiday" ? "linear-gradient(80deg, #FDDC7A 35%, #FE9F58)"
                            : item === "Comp Off" && "linear-gradient(80deg, #0DAC45, #0086FF)",
                          ...dayDesColor(item),
                          border:
                            item === "Admin / Hr"
                              ? "1px solid #666666"
                              : item === "Optional"
                                ? "1px dotted #666666"
                                : "none",
                        }}
                      ></div>
                      <div style={{ padding: "0.5rem" }}>
                        <Typography.Text style={{ width: "29vw", fontSize: 17 }} ellipsis={{ tooltip: true }}>
                          {item}
                        </Typography.Text>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        </div>
      </>
    );
  };
  useEffect(() => {

    return (() => {
      setSelectAssociate("")
    })
  }, [])
  useEffect(() => {
    let dates = `${moment().format("MM").slice(0, 3)},${year}`
    setMonthsVal(date === null ? dates : date)
    setLoader(true)
    getLogsDetails((res) => {
      setLoader(false)
      // setSelectAssociate("")
      setAttendanceLogsData(res)
    }, {
      userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
      month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date.split(",")[0]],
      year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
    })
    applyLeaveModalApi(
      {
        userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
      },
      (res) => {
        setApplyLeaveData(res);
      }
    );


  }, [])

  useEffect(() => {
    let associateNameArray = userCalendarDrop.filter((e) => e.userId === Number(associateId)).map((e) => e.userName);

    let selectedAssociate = selectAssociate !== "" ? selectAssociate
      : Object.keys(selectedUser).length > 0 ? selectedUser.name
        : associateNameArray[0]

    // setSelectedAssociateName(selectedAssociate);
    console.log(selectAssociate, selectedUser, associateId, selectedAssociateName, "jjjhfdsjkjkn");
    if (selectedAssociate !== selectedAssociateName) {
      setSelectedAssociateName(selectedAssociate);
    }

  }, [selectAssociate, selectedUser, associateId, userCalendarDrop, selectedAssociateName]);

  return (
    <>
      {loader ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center", width: width > 700 ? "100%" : "100vw",
            justifyContent: "center", backgroundColor: "#FFFFFF"
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ width: width > 700 ? "100%" : "100vw", backgroundColor: "#FFFFFF", padding: 0, margin: 0, height: "100vh" }}>
          <div style={{ height: width > 700 && "11vh" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "98%", marginTop: width > 700 && "0.5rem", padding: width < 700 && "0.6rem" }}>
              <h3 style={{ fontSize: width > 700 ? "1.3vw" : 23, marginLeft: "0.5rem", gap: "0.2rem", alignItems: "center", display: "flex" }}>
                <img src={MobileBackIcon} style={{ height: width > 700 ? "2.5vh" : "1.3rem", width: "1.2rem", cursor: "pointer" }}
                  onClick={() => {
                    setRender(1);
                    setUiSelectId(null);
                    navigate("/attendancePortal/attendance-logs");
                    let params = {
                      ExEmployee: tabs === "Inactive Users" ? true : false,
                      search: search,
                      // month : date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date?.split(",")[0]],
                      // year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
                      // selectedMonth === undefined ? date?.split(",")[1]:selectedMonth?.split(",")[1],
                      page: search === "" ? currentPage : "",
                      limit: search === "" ? pageSize : "",
                      departmentId: filteredDeptValue,
                      managerId: filteredManagerValue,
                      employeeIdSort: sortingEmployee
                    }
                    if (!startDate && !endDate) {
                      params = {
                        ...params, month: date === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[date?.split(",")[0]],
                        year: date === null ? dates?.split(",")[1] : date?.split(",")[1],
                      }
                    }
                    else if (startDate && endDate) {
                      params = { ...params, startDate: startDate, endDate: endDate }
                    }
                    getLogsData(params,
                      (res) => {
                        setLogData(res);
                        setTableMetaData(res?.meta)
                      },
                      setLoader
                    );
                  }}
                /> Attendance Logs
              </h3>
              {width > 700 ?

                <div style={{ display: "flex", flexDirection: "row", gap: "1rem", }}>
                  <Button
                    onclick={() => {
                      setManualPunchModal(true);
                    }}
                    background={"#0086FF"}
                    name="Multiple Punches"
                    fontColor={"white"}
                    borderRadius={5}
                    width="11vw"
                    height={width > 700 ? "5vh" : "41px"}
                    fontSize={width > 700 ? "1vw" : "18px"}
                    disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                  ></Button>
                  <div
                    onClick={() => {
                      if (Views?.hrAssociate && !Views?.hrAdmin) {

                      } else {
                        setApplyLeaveModal(true);
                      }
                    }}
                    style={{
                      display: "flex",
                      background: Views?.hrAssociate && !Views?.hrAdmin ? "grey" : "#0086FF",
                      alignItems: "center",
                      borderRadius: 5,
                      height: width > 700 ? "5vh" : "41px",
                      marginTop: 4,
                      padding: 5,
                      paddingLeft: 20,
                      width: width > 700 ? "10vw" : "10rem",
                      cursor: Views?.hrAssociate && !Views?.hrAdmin ? "not-allowed" : "pointer",
                    }}
                  >
                    <img
                      src={CalendarIcon}
                      style={{
                        width: "1rem",
                        height: width > 700 ? "0.8rem" : "1rem",
                        marginRight: "0.5rem",
                      }}
                    />
                    <div
                      style={{
                        fontSize: width > 700 ? "1vw" : "18px",
                        color: "white",
                        marginRight: 10,
                      }}
                    >
                      Apply Leave
                    </div>
                  </div>
                </div>
                :
                <div style={{ display: "flex", flexDirection: "row", gap: "0.7rem" }}>
                  <div className="m-t-10" style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFilterModalLogs(true);
                      setFilterModal(true);
                    }}>
                    <img src={FilterIcon} style={{ height: "1.4rem" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "0.3rem",
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: "600", color: "#0086FF"
                    }}
                    onClick={handleDownload}
                  >
                    {width > 700 && "Download"}
                    <img
                      src={DownloadIcon}
                      style={{ width: width > 700 ? "1rem" : "1.5rem", height: width > 700 ? "1rem" : "1.5rem" }}
                    />
                  </div>
                </div>
              }
            </div>
            {width < 700 &&
              <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", justifyContent: "flex-end" }}>
                <Button
                  onclick={() => {
                    setManualPunchModal(true);
                  }}
                  disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                  background={"#0086FF"}
                  name="Multiple Punches"
                  fontColor={"white"}
                  borderRadius={5}
                  width="22vh"
                  height="40px"
                  fontSize="17px"
                ></Button>
                <div
                  onClick={() => {
                    if (Views?.hrAssociate && !Views?.hrAdmin) {

                    } else {
                      setApplyLeaveModal(true);
                    }
                  }}
                  style={{
                    display: "flex",
                    background: Views?.hrAssociate && !Views?.hrAdmin ? "grey" : "#0086FF",
                    alignItems: "center",
                    borderRadius: 5,
                    height: "40px",
                    marginTop: 4,
                    padding: 5,
                    marginRight: 10,
                    paddingLeft: 20,
                    // width:width > 700 ?"8rem":"",
                    cursor: Views?.hrAssociate && !Views?.hrAdmin ? "not-allowed" : "pointer",
                  }}
                >
                  <img
                    src={CalendarIcon}
                    style={{
                      width: "1rem",
                      height: "0.8rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "17px",
                      color: "white",
                      marginRight: 10,
                    }}
                  >
                    Apply Leave
                  </div>
                </div>
              </div>}

            {width > 700 &&
              <div
                style={{
                  display: "flex", flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%", padding: "0 1rem"
                  // marginTop: "0.5rem",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: "1rem", }}
                // className={classes.nameDiv} 
                >
                  <div>
                    <Select
                      placeholder="Select Associate"
                      value={selectedAssociateName}
                      onChange={(e, val) => {
                   
                        if (e !== undefined) {
                          setLoader(true);
                          navigate(`/attendancePortal/attendance-logs/calendar/${e}`);
                          setSelectAssociate(val?.children);
                          setUiSelectId(e)
                          getLogsDetails(
                            (res) => {
                              setAttendanceLogsData(res);
                              if (monthsVal === null) {
                                setMonthsVal(`${moment().format("MM").slice(0, 3)},${year}`)
                              }
                            },
                            {
                              userId: e,
                              month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
                              year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
                            },
                            setLoader
                          );

                          applyLeaveModalApi(
                            {
                              userId: e,
                            },
                            (res) => {
                              setApplyLeaveData(res);
                            }
                          );
                        } else {

                          let associateIdInLog1 = localStorage.getItem("setAssociateIdInLog")
                          let name = localStorage.getItem("record")
                          console.log(selectedUser, "jjjjjjjjj");
                          setLoader(true);

                          navigate(`/attendancePortal/attendance-logs/calendar/${associateIdInLog1}`);
                          setSelectAssociate(selectedUser?.name);

                          setUiSelectId(associateIdInLog1)
                          getLogsDetails(
                            (res) => {
                              setAttendanceLogsData(res);
                              if (monthsVal === null) {
                                setMonthsVal(`${moment().format("MM").slice(0, 3)},${year}`)
                              }
                            },
                            {
                              userId: associateIdInLog1,
                              month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
                              year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
                            },
                            setLoader
                          );

                          applyLeaveModalApi(
                            {
                              userId: associateIdInLog1,
                            },
                            (res) => {
                              setApplyLeaveData(res);
                            }
                          );
                        }
                      }}
                      style={{ width: "15vw" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      {userCalendarDrop.map((user, index) => (
                        <>
                          <option key={user?.key} value={user.userId}>
                            {user?.userName}
                          </option>
                        </>
                      ))}
                    </Select>
                  </div>
                  <div style={{}}>

                    <DatePicker
                      allowClear={false}
                      value={monthsVal ? dayjs(monthsVal, "MM,YYYY") : null}
                      onChange={(date, dateString) => {
                        if (dateString) {
                          let datesAndYear = dateString.split('-');

                          setMonthsVal(`${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`);

                          // setSelectedMonth(datesAndYear[1]);
                          // setSelectedYear(datesAndYear[0]);
                          getLogsDetails(
                            (res) => {
                              setAttendanceLogsData(res);
                            },
                            {
                              // userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                              userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                              month: datesAndYear[1],
                              year: datesAndYear[0],
                            },
                            setLoader
                          );
                        }
                        else {
                          // setSelectedMonth(null);
                          // setSelectedYear(null);
                          setMonthsVal(null);
                          getLogsDetails(
                            (res) => {
                              setAttendanceLogsData(res);
                            },
                            {
                              // userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                              userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                              month: monthNameToNumber[dates.split(",")[0]],
                              year: dates?.split(",")[1],
                            },
                            setLoader
                          );
                        }
                      }} picker="month" style={{ height: "2rem" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "0.3rem",
                    cursor: "pointer",
                    fontSize: width > 700 ? "1.2vw" : "16px",
                    fontWeight: "600", color: "#0086FF"
                  }}
                  onClick={handleDownload}
                >
                  {width > 700 && "Download"}
                  <img
                    src={DownloadIcon}
                    style={{ width: width > 700 ? "1rem" : "1.5rem", height: width > 700 ? "1rem" : "1.5rem" }}
                  />
                </div>
              </div>
            }
          </div>
          <div style={{ maxHeight: width < 700 && "80vh", height: width > 700 && "77vh", overflow: "auto", backgroundColor: "#F9F9F9", marginTop: "0.5rem" }}>
            <LogsDataCard />
          </div>
        </div>
      )}

      {open && (
        <Modal centered
          onCancel={handleCancel}
          title={
            <div className="r-c-c" style={{ color: "#0DAC45", fontSize: width < 700 && "21px" }}>
              <AiOutlineVerticalAlignBottom
                style={{
                  border: "0px solid black",
                  height: "20px",
                  width: "20px",
                  color: "#0DAC45",
                  marginRight: 5,
                }}
              />
              Download
            </div>
          }
          footer={
            <div className="r-c-c" style={{ margin: "1rem", display: "flex", flexDirection: "row", gap: "0.5rem" }}>
              <Button
                name="Cancel"
                background={"#FFFFFF"}
                onclick={handleCancel}
                fontSize={width < 700 ? 17 : 15}
                fontColor={"#0086FF"}
                height={width > 700 ? "2.1rem" : "2.4rem"}
                width="5.5rem"
                marginLeft="5px"
                borderRadius={8}
              />
              <Button
                name={value === 1 ? "Next" : "Download"}
                loader={btnLoader}
                background={"#0086FF"}
                onclick={() => {
                  if (value === 1) {
                    handleCancel();
                    applyFiltersModalHandler(true);
                  } else if (value === 3) {
                    setBtnLoader(true);

                    Api.get(`/attendance-logs/detailReport`)
                      .params({
                        month: date === undefined ? moment().format("MM") : monthNameToNumber[date?.split(",")[0]],
                        year: date === undefined ? moment().format("YYYY") : date?.split(",")[1],
                        exEmployee: tabs === "Inactive Users" ? true : false,
                        search: search,
                        departmentId: filteredDeptValue,
                        managerId: filteredManagerValue,
                      })
                      .send((response) => {

                        window.open(response.file, "_blank")?.focus();
                        setBtnLoader(false);
                        handleCancel();
                      })
                  } else if (value === 2) {
                    setBtnLoader(true);
                    DownLoadAttendnaceLogs(
                      () => {
                        setBtnLoader(false);
                        handleCancel();
                      },
                      `/attendance-logs/summaryReport`,
                      {

                        month: date === undefined ? moment().format("MM") : monthNameToNumber[date?.split(",")[0]],
                        year: date === undefined ? moment().format("YYYY") : date?.split(",")[1],
                        exEmployee: tabs === "Inactive Users" ? true : false,
                        search: search,
                        departmentId: filteredDeptValue,
                        managerId: filteredManagerValue

                      }
                    );
                    handleCancel();
                  }
                }}
                fontSize={width < 700 ? 17 : 15}
                fontColor={"#FFFFFF"}
                height={width > 700 ? "2.1rem" : "2.4rem"}
                width="6rem"
                borderRadius={8}
                style={{ marginRight: "0.5rem" }}
              />
            </div>
          }
          open={open} width={850}
        >
          <Divider />
          <div className="r-c-c" style={{ height: "18vh" }}>
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical" style={{ display: "flex", flexDirection: "column", gap: "0.5rem", }}>
                <Radio value={1} style={{ fontSize: width < 700 && "18px" }}>Custom download</Radio>
                <Radio value={2} style={{ fontSize: width < 700 && "18px" }}>Summary Report</Radio>
                <Radio value={3} style={{ fontSize: width < 700 && "18px" }}>Detail Report</Radio>
              </Space>
            </Radio.Group>
          </div>
        </Modal>
      )}
      {filterModal && (
        <Modal centered
          onCancel={() => { setFilterModal(false); setFilterModalLogs(false) }}
          title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: "21px" }}>
            Filters
          </div>
          }
          footer={
            <div className="r-c-c" style={{ margin: "1rem", marginTop: "1.4rem", display: "flex", flexDirection: "row", gap: "0.5rem" }}>
              <Button
                name="Cancel"
                background={"#FFFFFF"}
                onclick={() => { setFilterModal(false); setFilterModalLogs(false) }}
                fontSize={17}
                fontColor={"#0086FF"}
                height="2.4rem"
                width="5.5rem"
                marginLeft="5px"
                borderRadius={8}
              />
              <Button
                name="Apply"
                background={"#0086FF"}
                onclick={() => {
                  if (filterModalLogs === true) {
                    setFilterModal(false)
                    setFilterModalLogs(false)
                  } else {

                    setFilterModalLogs(false)
                    setFilterModal(false)
                  }
                }}
                fontSize={17}
                fontColor={"#FFFFFF"}
                height="2.4rem"
                width="5.5rem"
                borderRadius={8}
                style={{ marginRight: "0.5rem" }}
              />
            </div>
          }
          open={filterModal} width={750}
        >
          <Divider />
          {filterModalLogs ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Associate</p>
              <Select
                placeholder="Select Associate"
                value={selectedAssociateName}
                onChange={(e, val) => {
                  setLoader(true);
                  setSelectAssociate(val?.children);
                  setUiSelectId(e)
                  getLogsDetails(
                    (res) => {
                      setAttendanceLogsData(res);
                    },
                    {
                      userId: e,
                      // month: attendanceLogsData?.month,
                      // year: moment().format("YYYY"),
                      // month : monthNameToNumber[monthsVal.split(",")[0]],
                      // year: monthsVal?.split(",")[1],
                      month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
                      year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
                    },
                    setLoader
                  );
                  applyLeaveModalApi(
                    {
                      userId: e,
                    },
                    (res) => {
                      setApplyLeaveData(res);
                    }
                  );
                }}
                style={{ height: "3rem", fontSize: "19px", }}
                showSearch
                size="large"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                {userCalendarDrop.map((user, index) => (
                  <>
                    <option key={user?.key} value={user.userId} style={{ fontSize: "19px" }}>
                      {user?.userName}
                    </option>
                  </>
                ))}
              </Select>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Month</p>

              <DatePicker allowClear={false}
                value={monthsVal ? dayjs(monthsVal, "MM,YYYY") : null}
                onChange={(date, dateString) => {
                  if (dateString) {
                    let datesAndYear = dateString.split('-');
                    setMonthsVal(`${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`);

                    //  setSelectedMonth(datesAndYear[1]);
                    //  setSelectedYear(datesAndYear[0]);
                    getLogsDetails(
                      (res) => {
                        setAttendanceLogsData(res);
                      },
                      {
                        //   userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                        userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                        month: datesAndYear[1],
                        year: datesAndYear[0],
                      },
                      setLoader
                    );
                  } else {
                    // setSelectedMonth(null);
                    // setSelectedYear(null);
                    setMonthsVal(null);
                    getLogsDetails(
                      (res) => {
                        setAttendanceLogsData(res);
                      },
                      {
                        //   userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                        userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                        month: monthNameToNumber[dates.split(",")[0]],
                        year: dates?.split(",")[1],
                      },
                      setLoader
                    );
                  }
                }} picker="month" style={{ height: "2rem" }}
              />
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Date</p>

              <DatePicker
                value={dayjs(date, "MM,YYYY")}
                onChange={(date, dateString) => {
                  let datesAndYear = dateString.split('-');
                  setDate(`${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`);
                  setMonthsVal(`${moment(datesAndYear[1]).format("MM").slice(0, 3)},${datesAndYear[0]}`);

                }} picker="month" style={{ height: "2.05rem" }}
              />
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Manager</p>
              <Select placeholder="Manager" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                value={filteredManagerValue}
                size="large"
                style={{
                  height: "3rem", fontSize: "18px"
                }}
                onChange={(e) => {
                  setFilteredManagerValue(e);
                  setCurrentPage(1);
                }}
                disabled={false}
                optionFilterProp="children"
              // filterOption={(input, option) =>
              //   (option?.label ?? "")
              //     .toLowerCase()
              //     .includes(input.toLowerCase())
              // }
              >
                {filteredData?.department?.allManager?.map((option, index) => {
                  return (<>{option.name !== undefined &&
                    <Option key={index} value={option.id} style={{ fontSize: "19px" }}>
                      {option.name}
                    </Option>}</>)
                })}
              </Select>
              <p style={{ fontSize: "18px", color: "#878787" }}>Select Department</p>
              <Select placeholder="Department" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                value={filteredDeptValue}
                size="large"
                style={{
                  height: "3rem", fontSize: "18px"
                }}
                onChange={(e) => {
                  setFilteredDeptValue(e);
                  setCurrentPage(1);
                }}
                disabled={false}
                optionFilterProp="children"
              // filterOption={(input, option) =>
              //   (option?.label ?? "")
              //     .toLowerCase()
              //     .includes(input.toLowerCase())
              // }
              >
                {filteredData?.department?.result?.map((option, index) => {
                  return (<>{option.department !== undefined &&
                    <Option key={index} value={option.departmentId} style={{ fontSize: "19px" }}>
                      {option.department}
                    </Option>}</>)
                })}
              </Select>
            </div>
          }
        </Modal>
      )}
      <DownloadModal
        onDownload={onDownload}
        visible={isApplyFiltersModal}
        applyFiltersModalHandler={applyFiltersModalHandler}
        onCancel={() => {
          applyFiltersModalHandler(false);
        }}
        Tabs={tabs}
      />
      {/* <ApplyFiltersModalG onSave={onSave} reference={1} getFilterValues={getFilterValues} isApplyFiltersModal={isApplyFiltersModal} applyFiltersModalHandler={applyFiltersModalHandler} /> */}
      {
       
        <UpdateAttendanceModal uiSelectId={uiSelectId}
          visible={updateModal}
          reload={() => {
            getLogsDetails(
              (res) => {
                setAttendanceLogsData(res);
              },
              {
                //   userId: uiSelectId ? uiSelectId : selectedUser?.userId,
                userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
                // month : monthNameToNumber[monthsVal.split(",")[0]],
                // year: monthsVal?.split(",")[1],
                month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
                year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
                download: undefined,
              },
              setLoader
            );
          }}
          onCancel={() => {
            setUpdateModal(false);
          }}
          Data={Data}
          setData={setData}
          selectDate={selectData}
        />
      }

      <ManualPunchModal
        visible={manualPunchModal}
        uiSelectId={uiSelectId}
        reload={() => {
          getLogsDetails(
            (res) => {
              setAttendanceLogsData(res);
            },
            {
              userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
              // month : monthNameToNumber[monthsVal.split(",")[0]],
              // year: monthsVal?.split(",")[1],
              month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
              year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
              download: undefined,
            },
            setLoader
          );
        }}
        onCancel={() => {
          setManualPunchModal(false);
        }}
      />
      <GeoFence
        modalVisible={openGeofence} uiSelectId={uiSelectId} selectDate={selectData}
        dateMonthYear={date}
        closeModal={() => {
          setOpenGeofence(false);
        }}
      />
      <ApplyLeave year={monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1]} uiSelectId={uiSelectId} selectDate={selectData} reload={() => {
        getLogsDetails(
          (res) => {
            setAttendanceLogsData(res);
          },
          {
            //   userId: uiSelectId ? uiSelectId : selectedUser?.userId,
            userId: uiSelectId ? uiSelectId : Object.keys(selectedUser).length > 0 ? selectedUser?.userId : associateId,
            // month : monthNameToNumber[monthsVal.split(",")[0]],
            // year: monthsVal?.split(",")[1],
            month: monthsVal === null ? monthNameToNumber[dates.split(",")[0]] : monthNameToNumber[monthsVal.split(",")[0]],
            year: monthsVal === null ? dates?.split(",")[1] : monthsVal?.split(",")[1],
            download: undefined,
          },
          setLoader
        );
      }} />
    </>
  )
}
export default CalendarIconPage;