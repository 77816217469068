import React, { useEffect } from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import PMTRoutes from './components/PMTRoutes';
import useStore from '../src/pages/store';
import Api from './services/Api';
import { SECRET_KEY, decryptData } from './services/fileCode';
import viewClass from './services/ViewRole';
import './index.css';
// import { isJwtExpired } from 'jwt-check-expiration';
import isJwtTokenExpired, { decode } from 'jwt-check-expiry';
import Notification from './services/Notification';
// import { jwtDecode } from 'jwt-decode';
function App() {
  
  const {user} = useStore(state=>state);

  useEffect(() => {
    let tt=localStorage.getItem('token');
    if(tt){
    let token=decryptData(tt,SECRET_KEY)
    if (token) {
      Api.header(token);
    }
  }
  }, [localStorage.getItem('token')]);
useEffect(()=>{
  viewClass.updateView();
},[user])

useEffect(() => {
  let tt=localStorage.getItem('token');
  if(tt){
    let token=decryptData(tt,SECRET_KEY);
    let isExpired=isJwtTokenExpired(token);
    console.log("isExpired token:",isExpired,token,decode(token));
    if(isExpired){
       Notification.error("Error","token expired")
       setTimeout(() =>{
        window.localStorage.clear()
     
        window.location.replace('/login')
       },2000)
       
     }
  }
  else{
   
    window.localStorage.clear()
      
  }
 
 },[window.location.pathname]);

  return (
    <Router>
      {/* <Header /> */}
      <PMTRoutes/>
    </Router>
  );
}

export default App;
